/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ManualProgressKeyResultDto } from '@chroma-x/frontend/domain/okr/api-integration';

import { baseKeyResultDtoMockData } from './base-key-result-dto-mock-data';

export const manualProgressKeyResultDtoMockData: ManualProgressKeyResultDto = {
	...baseKeyResultDtoMockData,
	progressMode: 'MANUAL',
	progress: 0.5
};
