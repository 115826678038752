import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { Model } from '@chroma-x/frontend/core/domain-model';

import { CollectionServiceFoundation } from './collection-service-foundation';
import { ActionStatus } from '../../meta/action-status';
import { FetchStatus } from '../../meta/fetch-status';
import { SetArg } from '../zustand-util';

/**
 * Creates an operation to reset the fetch status of the collection.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @returns A function that resets the fetch status.
 */
export const createResolveFetchStatusDefaultMetaOperation = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	return () => {
		set((state) => {
			state.data.meta.fetchStatus = FetchStatus.IDLE;
		});
	};
};

/**
 * Creates an operation to reset the fetch status of a specific entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @returns A function that resets the fetch status of an entity.
 */
export const createResolveEntityFetchStatusDefaultMetaOperation = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	return (id: ModelPrimaryKey) => {
		set((state) => {
			state.data.meta.fetchEntityStatus[id] = FetchStatus.IDLE;
		});
	};
};

/**
 * Creates an operation to reset the action status of the collection.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @returns A function that resets the action status.
 */
export const createResolveActionStatusDefaultMetaOperation = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	return () => {
		set((state) => {
			state.data.meta.actionStatus = ActionStatus.IDLE;
		});
	};
};

/**
 * Creates an operation to reset the action status of a specific entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @returns A function that resets the action status of an entity.
 */
export const createResolveEntityActionStatusDefaultMetaOperation = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	return (id: ModelPrimaryKey) => {
		set((state) => {
			state.data.meta.actionEntityStatus[id] = ActionStatus.IDLE;
		});
	};
};
