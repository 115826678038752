import { EventData } from './event-data';
import { LogLevel } from './log-level';
import { Logger } from './logger';

/**
 * A logger that distributes log messages to multiple underlying loggers.
 */
export class DistributingLogger implements Logger {

	private readonly loggers: Array<Logger>;

	/**
	 * Creates a new DistributingLogger.
	 *
	 * @param loggers - The loggers to which log messages will be distributed.
	 */
	public constructor(loggers: Array<Logger> = []) {
		this.loggers = loggers;
	}

	/**
	 * Adds a logger to the list of loggers to which log messages are distributed.
	 *
	 * @param logger - The logger to add.
	 */
	public addLogger(logger: Logger) {
		this.loggers.push(logger);
	}

	/**
	 * Logs an event to all the underlying loggers.
	 *
	 * @param message - The message to log.
	 * @param data - Additional data to log.
	 * @param category - The category of the event.
	 * @param level - The log level.
	 * @returns A Promise that resolves when the event is logged to all the underlying loggers.
	 */
	public async logEvent(message: string, data?: EventData, category?: string, level?: LogLevel): Promise<void> {
		for (const logger of this.loggers) {
			await logger.logEvent(message, data, category, level);
		}
	}

	/**
	 * Logs an error to all the underlying loggers.
	 *
	 * @param error - The error to log.
	 * @param level - The log level.
	 * @returns A Promise that resolves when the error is logged to all the underlying loggers.
	 */
	public async logError(error: Error, level?: LogLevel): Promise<void> {
		for (const logger of this.loggers) {
			await logger.logError(error, level);
		}
	}

	/**
	 * Logs a message to all the underlying loggers.
	 *
	 * @param message - The message to log.
	 * @param level - The log level.
	 * @returns A Promise that resolves when the message is logged to all the underlying loggers.
	 */
	public async logMessage(message: unknown, level?: LogLevel): Promise<void> {
		for (const logger of this.loggers) {
			await logger.logMessage(message, level);
		}
	}

}
