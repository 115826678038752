/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { Maybe } from '@chroma-x/common/core/util';
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';
import {
	BaseObjectiveCreateModel,
	ObjectiveProgressMode,
	ObjectiveScope,
	ObjectiveTimeframe
} from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveCreateDto } from '../../../../../dto';

export class BaseObjectiveCreateRequestModelConverter extends AbstractRequestModelConverter<
	BaseObjectiveCreateModel,
	BaseObjectiveCreateDto
> {
	public toDto(model: BaseObjectiveCreateModel): BaseObjectiveCreateDto {
		return {
			title: model.title satisfies string,
			descriptionString: model.descriptionString satisfies Maybe<string>,
			scope: model.scope satisfies ObjectiveScope,
			timeframe: model.timeframe satisfies ObjectiveTimeframe,
			progressMode: model.progressMode satisfies ObjectiveProgressMode
		};
	}
}
