import process from 'process';

import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Routes } from 'react-router-dom';

/**
 * Returns the appropriate Routes component based on the value of the NX_PUBLIC_SENTRY_ENABLED environment variable.
 * If the variable is set to 'true', the Routes component is wrapped with the withSentryReactRouterV6Routing function
 * from the @sentry/react package. Otherwise, the Routes component is returned as is.
 *
 * @returns The appropriate Routes component.
 */
export const useSentryRoutes = () => {

	let RoutesElement;
	if (process.env.NX_PUBLIC_SENTRY_ENABLED === 'true') {
		RoutesElement = withSentryReactRouterV6Routing(Routes);
	} else {
		RoutesElement = Routes;
	}
	return RoutesElement;

};
