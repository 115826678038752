/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { KeyResultBasedProgressObjectiveDto } from '@chroma-x/frontend/domain/okr/api-integration';

import { baseObjectiveDtoMockData } from './base-objective-dto-mock-data';

export const keyResultBasedProgressObjectiveDtoMockData: KeyResultBasedProgressObjectiveDto = {
	...baseObjectiveDtoMockData,
	progressMode: 'KEY_RESULTS',
	progress: 0.5
};
