import { ObjectiveHistoryServiceApiClient } from '@chroma-x/frontend/domain/okr/api-integration';
import { ObjectiveHistoryApiFetchMockConfiguration } from '@chroma-x/frontend/domain/okr/api-integration-mock';

export class ObjectiveHistoryServiceApiClientFactory {

	public static create() {
		if (process.env.NX_PUBLIC_OKR_API_MOCKED === 'true') {
			new ObjectiveHistoryApiFetchMockConfiguration().apply();
		}
		return new ObjectiveHistoryServiceApiClient();
	}

}
