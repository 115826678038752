import { Maybe } from './maybe';

enum CharCase {
	NONE,
	NUMERIC,
	UPPERCASE,
	LOWERCASE
}

/**
 * Removes all whitespace from a string.
 *
 * @param string - The string to remove whitespace from.
 * @returns The string with all whitespace removed.
 */
export const removeWhitespace = (string: string): string => {
	return string.replace(/\s/g, '');
};

/**
 * Removes all non-numeric characters from a string.
 *
 * @param string - The string to remove non-numeric characters from.
 * @returns The string with all non-numeric characters removed.
 */
export const removeNonNumericChars = (string: string): string => {
	return string.replace(/\D/g, '');
};

/**
 * Trims a string from both the left and right sides.
 *
 * @param string - The string to trim.
 * @param char - The character to trim.
 * @returns The trimmed string.
 */
export const trim = (string: string, char: string): string => {
	return trimFromRight(trimFromLeft(string, char), char);
};

/**
 * Trims a string from the right side.
 *
 * @param string - The string to trim.
 * @param char - The character to trim.
 * @returns The trimmed string.
 */
export const trimFromRight = (string: string, char: string): string => {
	while (string.endsWith(char)) {
		string = string.slice(0, -1 * char.length);
	}
	return string;
};

/**
 * Trims a string from the left side.
 *
 * @param string - The string to trim.
 * @param char - The character to trim.
 * @returns The trimmed string.
 */
export const trimFromLeft = (string: string, char: string): string => {
	while (string.startsWith(char)) {
		string = string.slice(char.length);
	}
	return string;
};

/**
 * Converts a string to camel case.
 *
 * @param string - The string to convert.
 * @returns The converted string.
 */
export const camelCase = (string: string): string => {
	const stringFragments = string
		.toLowerCase()
		.split(/[\t\n\s,\-_]+/)
		.filter((fragment): boolean => {
			return fragment.length > 0;
		})
		.map((fragment): string => {
			return fragment[0].toUpperCase() + (fragment.substring(1) ?? '');
		});
	const combined = stringFragments.join('');
	return (combined[0]?.toLowerCase() ?? '') + (combined.substring(1) ?? '');
};

/**
 * Converts a string to upper case.
 *
 * @param value - The string to convert.
 * @returns The converted string.
 */
export const upperCase = (value: string) => {
	const stringFragments: Array<string> = [];
	let current = '';
	for (const char of value) {
		const caseChar = getCharCase(char);
		if (caseChar === CharCase.NONE) {
			stringFragments.push(current);
			current = '';
			continue;
		}
		if (caseChar === CharCase.NUMERIC) {
			stringFragments.push(current);
			stringFragments.push(char);
			current = '';
			continue;
		}
		if (caseChar === CharCase.UPPERCASE) {
			stringFragments.push(current);
			current = char;
			continue;
		}
		current += char;
	}
	stringFragments.push(current);
	return stringFragments
		.filter((fragment): boolean => {
			return fragment.length > 0;
		})
		.map((fragment): string => {
			return fragment.toUpperCase();
		})
		.join('_');
};

/**
 * Checks if the given value is a numeric string.
 *
 * @param value - The value to check.
 * @returns True if the value is a numeric string, false otherwise.
 */
export const isNumeric = (value: string): boolean => {
	return !isNaN(Number(value));
};

/**
 * Checks if the given value is a string.
 *
 * @param value - The value to check.
 * @returns True if the value is a string, false otherwise.
 */
export const isString = (value: unknown): value is string => {
	return typeof value === 'string' || value instanceof String;
};

/**
 * Checks if the given value is a string or undefined.
 *
 * @param value - The value to check.
 * @returns True if the value is a string or undefined, false otherwise.
 */
export const isMaybeString = (value: unknown): value is Maybe<string> => {
	return value === undefined || isString(value);
};

/**
 * Truncates a string from the right side.
 *
 * @param string - The string to truncate.
 * @param targetLength - The target length of the truncated string.
 * @param placeholder - The placeholder to append to the truncated string.
 * @returns The truncated string.
 */
export const truncateRight = (string: string, targetLength: number, placeholder = ' …'): string => {
	if (string.length <= targetLength) {
		return string;
	}
	return string.slice(0, targetLength - placeholder.length) + placeholder;
};

/**
 * Truncates a string from the left side.
 *
 * @param string - The string to truncate.
 * @param targetLength - The target length of the truncated string.
 * @param placeholder - The placeholder to prepend to the truncated string.
 * @returns The truncated string.
 */
export const truncateLeft = (string: string, targetLength: number, placeholder = '… '): string => {
	if (string.length <= targetLength) {
		return string;
	}
	return placeholder + string.slice((targetLength - placeholder.length) * -1);
};

/**
 * Truncates a string from the center.
 *
 * @param string - The string to truncate.
 * @param targetLength - The target length of the truncated string.
 * @param placeholder - The placeholder to insert in the truncated string.
 * @returns The truncated string.
 */
export const truncateCenter = (string: string, targetLength: number, placeholder = ' … '): string => {
	if (string.length <= targetLength) {
		return string;
	}
	const sliceIndex = (targetLength - placeholder.length) / 2;
	return string.slice(0, Math.ceil(sliceIndex)) + placeholder + string.slice(Math.ceil(sliceIndex * -1));
};

/**
 * Converts a string to a filesystem-safe string.
 *
 * @param string - The string to convert.
 * @returns The converted string.
 */
export const toFilesystemSafe = (string: string): string => {
	const replacementMap = new Map([
		['Ä', 'Ae'],
		['Ö', 'Oe'],
		['Ü', 'Ue'],
		['ä', 'ae'],
		['ö', 'oe'],
		['ü', 'ue'],
		['ß', 'ss']
	]);
	const pattern = new RegExp(Array.from(replacementMap.keys()).join('|'), 'gi');
	string = string.replace(pattern, (match) => {
		return replacementMap.get(match) ?? '-';
	});
	string = string.replace(/[^0-9a-z]/gi, '-');
	return string.trim();
};

const getCharCase = (char: string): CharCase => {
	const charCode = char.charCodeAt(0);
	if (charCode >= 48 && charCode <= 57) {
		return CharCase.NUMERIC;
	}
	if (charCode >= 65 && charCode <= 90) {
		return CharCase.UPPERCASE;
	}
	if (charCode >= 97 && charCode <= 122) {
		return CharCase.LOWERCASE;
	}
	return CharCase.NONE;
};
