import { ObjectiveProgressMode, ObjectiveScope, ObjectiveTimeframe } from '@chroma-x/frontend/domain/okr/domain-model';
import { useObjectiveService } from '@chroma-x/frontend/domain/okr/react-service-provider';

export function ObjectiveCreateForm() {

	const service = useObjectiveService();

	const handleCreate = () => {
		void service.command.create({
			progressMode: ObjectiveProgressMode.MANUAL,
			title: 'Awesome objective ' + Math.random(),
			scope: ObjectiveScope.ENGINEERING,
			timeframe: ObjectiveTimeframe.LONG_TERM
		});
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<fieldset>
				<legend>Create objective</legend>
				<button onClick={handleCreate}>Create</button>
			</fieldset>
		</form>
	);
}
