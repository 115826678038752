import { merge } from 'lodash-es';

import { isString, Nullable, Timeout } from '@chroma-x/common/core/util';

import { flattenLiteralStruct } from './locale.util';

type LiteralPrimitives = string;
type LiteralStructValue =
	LiteralPrimitives
	| ReadonlyArray<LiteralPrimitives>
	| { [x: string]: LiteralStructValue }
	| ReadonlyArray<LiteralStructValue>;
export type LiteralStruct = Record<string, LiteralStructValue>;

export type LiteralBundleUrl = string;

export class Locale {

	public readonly identifier: string;
	private readonly literalDefinitions: Array<LiteralStruct | LiteralBundleUrl>;
	private warmed = false;
	private literals: Record<string, string> = {};

	/**
	 * Constructor for the Locale class.
	 *
	 * @param identifier - The identifier of the locale.
	 * @param literalsDefinitions - The definitions of the literals.
	 */
	constructor(identifier: string, literalsDefinitions: Array<LiteralStruct | LiteralBundleUrl>) {
		this.identifier = identifier;
		this.literalDefinitions = literalsDefinitions;
	}

	/**
	 * Selects the locale.
	 *
	 * @returns A Promise that resolves when the locale is selected.
	 */
	public async select(): Promise<void> {
		if (this.warmed) {
			return;
		}
		const literalDefinitions = (await Promise.all(
			this.literalDefinitions.map(async (literalDefinition) => {
				if (isString(literalDefinition)) {
					return this.loadLiterals(literalDefinition);
				}
				return literalDefinition;
			})
		)).filter((literalDefinition): literalDefinition is LiteralStruct => {
			return literalDefinition !== null;
		});
		const mergedLiterals = {};
		merge(mergedLiterals, ...literalDefinitions);
		this.literals = flattenLiteralStruct(mergedLiterals);
		this.warmed = true;
	}

	/**
	 * Adds literals to the locale.
	 *
	 * @param literals - The literals to add.
	 * @param prefix - Optional prefix for the literals.
	 */
	public addLiterals(literals: LiteralStruct, prefix?: string) {
		const keyChain = prefix ? [prefix] : [];
		const flattenedLiterals = flattenLiteralStruct(literals, keyChain);
		this.literals = { ...this.literals, ...flattenedLiterals };
	}

	/**
	 * Translates a given literal string by replacing placeholders with values.
	 *
	 * @param literal - The literal string to translate.
	 * @param replacements - Optional map of placeholders and their corresponding values.
	 * @param defaultValue - Optional default value to return if the literal is not found.
	 * @returns The translated string or null if the literal is not found.
	 */
	public translate(literal: string, replacements?: Map<string, string>, defaultValue: Nullable<string> = null): string | null {
		let result: Nullable<string>;
		if (literal in this.literals) {
			result = this.literals[literal];
		} else {
			result = defaultValue;
		}

		if (result === null) {
			return null;
		}

		if (replacements !== undefined) {
			replacements.forEach((value, key) => {
				result = (result as string).replace('{{' + key + '}}', value);
			});
		}
		return result;
	}

	private async loadLiterals(url: LiteralBundleUrl): Promise<Nullable<LiteralStruct>> {
		try {
			const abortController = new AbortController();
			const request = new Request(
				url,
				{
					signal: abortController.signal,
					method: 'GET',
					cache: 'no-cache',
					headers: {
						'accept': 'application/json',
						'content-type': 'application/json'
					}
				}
			);
			const response = await Timeout.wrap<Response>(fetch(request), 5000, new Error('Request timeout'), (): void => {
				abortController.abort();
			});
			return await response.json() as LiteralStruct;
		} catch (e) {
			console.warn(e);
			return null;
		}
	}

}
