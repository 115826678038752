import process from 'process';

import { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Auth0ApiClientMock } from '@chroma-x/frontend/core/auth0-integration';
import { KeycloakApiClient } from '@chroma-x/frontend/core/keycloak-integration';
import { EventType, LogLevel } from '@chroma-x/frontend/core/logger';
import { OauthApiClient } from '@chroma-x/frontend/core/oauth-api-integration';
import { AuthGuard, AuthGuardInterceptingProps } from '@chroma-x/frontend/core/react-auth-guard';
import { AuthProvider } from '@chroma-x/frontend/core/react-auth-provider';
import { useFeatureFlag } from '@chroma-x/frontend/core/react-feature-flag-provider';
import { useLogger } from '@chroma-x/frontend/core/react-logger';
import { PermissionProvider } from '@chroma-x/frontend/core/react-permission-provider';
import { useServiceManager } from '@chroma-x/frontend/core/react-service-provider';
import { OauthEmitterFactory } from '@chroma-x/frontend/core/react-ui-auth-emitter';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';
import { RoutePath as OkrRoutePath } from '@chroma-x/frontend/domain/okr/react-ui-domain';

import { Router } from './router/router';
import { RoutePath } from './router/routes';
import { FeatureToggle } from '../features/feature-toggle';

import './app.scss';

export function App() {

	const navigate = useNavigate();
	const logger = useLogger();
	const serviceManager = useServiceManager();

	const testFeatureEnabled = useFeatureFlag(FeatureToggle.TEST_FEATURE);

	let oauthApiClient: OauthApiClient;
	if (process.env.NX_PUBLIC_OAUTH_CLIENT_MOCKED === 'true') {
		oauthApiClient = new Auth0ApiClientMock();
	} else {
		//oauthApiClient = new Auth0ApiClient();
		oauthApiClient = new KeycloakApiClient();
	}

	const handleAuthenticated = () => {
		logger?.logEvent('Authenticated', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		navigate(buildRouteUrl(RoutePath.ROOT));
	};

	const handleReauthenticate = () => {
		logger?.logEvent('Reauthenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		serviceManager.resetAll();
	};

	const handleUnauthenticate = () => {
		logger?.logEvent('Unauthenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		serviceManager.resetAll();
		try {
			const redirectUrl = new URL(window.location.origin);
			redirectUrl.pathname = buildRouteUrl(RoutePath.GOODBYE);
			oauthApiClient.logout(redirectUrl.toString());
		} catch (e) {
			console.warn(e);
		}
	};

	const handleUnauthenticated = () => {
		logger?.logEvent('Unauthenticated', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		serviceManager.resetAll();
		navigate(buildRouteUrl(RoutePath.GOODBYE));
	};

	const handleTokenReceived = () => {
		logger?.logEvent('Authenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
	};

	const renderAuthEmitter = (): ReactElement<AuthGuardInterceptingProps> => {
		return (<OauthEmitterFactory onTokenReceived={handleTokenReceived} />);
	};

	return (
		<>
			<pre>
				Test feature flag is {testFeatureEnabled ? 'ON' : 'OFF'}
			</pre>
			<hr />
			<nav>
				<Link to={buildRouteUrl(RoutePath.ROOT)}>Root</Link>
				<Link to={buildRouteUrl(OkrRoutePath.OKR_INITIATIVE_HISTORY)}>Initiative history</Link>
				<Link to={buildRouteUrl(OkrRoutePath.OKR_OBJECTIVES)}>Objective</Link>
			</nav>
			<AuthProvider
				oauthApiClient={oauthApiClient}
				onAuthenticated={handleAuthenticated}
				onReauthenticate={handleReauthenticate}
				onUnauthenticate={handleUnauthenticate}
				onUnauthenticated={handleUnauthenticated}
			>
				<AuthGuard interceptingComponent={renderAuthEmitter}>
					<PermissionProvider>
						<Router />
					</PermissionProvider>
				</AuthGuard>
			</AuthProvider>
		</>
	);

}
