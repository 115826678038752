/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { MarkdomDocument } from '@markdom/model';

import { BaseKeyResultDto } from '@chroma-x/frontend/domain/okr/api-integration';

export const baseKeyResultDtoMockData: BaseKeyResultDto = {
	id: '0c0a0470-11a4-4805-a070-13162f2d9023',
	objective: '0c0a0470-11a4-4805-a070-13162f2d9023',
	title: 'My awesome key result',
	descriptionDocument: {
		version: {
			major: 1,
			minor: 0
		},
		blocks: [
			{
				type: 'PARAGRAPH',
				contents: [
					{
						type: 'EMPHASIS',
						level: 2,
						contents: [
							{
								type: 'TEXT',
								text: 'Super key result'
							}
						]
					},
					{
						type: 'TEXT',
						text: ' description as commonmark string'
					}
				]
			}
		]
	} as MarkdomDocument,
	status: 'STARTED',
	progressMode: 'MANUAL',
	responsibles: ['0c0a0470-11a4-4805-a070-13162f2d9023'],
	followers: ['0c0a0470-11a4-4805-a070-13162f2d9023'],
	createdAt: '2024-05-28T16:02:57.919Z',
	createdBy: '0c0a0470-11a4-4805-a070-13162f2d9023',
	updatedAt: '2024-05-28T16:02:57.919Z',
	updatedBy: '0c0a0470-11a4-4805-a070-13162f2d9023',
	deletedAt: '2024-05-28T16:02:57.919Z',
	deletedBy: '0c0a0470-11a4-4805-a070-13162f2d9023'
};
