/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { guardProbably } from '@chroma-x/common/core/util';
import { ManualProgressObjectiveModel, ObjectiveProgressMode } from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveResponseModelConverter } from './base-objective-response-model-converter';
import { ManualProgressObjectiveDto } from '../../../../dto';

export class ManualProgressObjectiveResponseModelConverter extends BaseObjectiveResponseModelConverter {
	public toModel(dto: ManualProgressObjectiveDto): ManualProgressObjectiveModel {
		return {
			...super.toModel(dto),
			progressMode: guardProbably(
				dto.progressMode,
				new ApiError("Expected property 'progressMode' of 'ManualProgressObjectiveDto' missing.")
			) as ObjectiveProgressMode.MANUAL,
			progress: guardProbably(
				dto.progress,
				new ApiError("Expected property 'progress' of 'ManualProgressObjectiveDto' missing.")
			) satisfies number
		};
	}
}
