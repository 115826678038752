/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractModelConverter } from './foundation/abstract-model-converter';

/**
 * This class is a No-Operation (Noop) implementation of the ModelConverter.
 * It simply returns the input as-is without any conversion.
 *
 * @example
 * const noopConverter = new NoopModelConverter();
 * const input = { name: 'John Doe', age: 30 };
 * const output = noopConverter.toDto(input);
 * console.log(output); // Output: { name: 'John Doe', age: 30 }
 */
export class NoopModelConverter extends AbstractModelConverter<any, any> {

	/**
	 * Converts a model to a DTO.
	 *
	 * @param model - The model to convert.
	 * @returns The converted DTO.
	 */
	public toDto(model: any): any {
		return model;
	}

	/**
	 * Converts a DTO to a model.
	 *
	 * @param dto - The DTO to convert.
	 * @returns The converted model.
	 */
	public toModel(dto: any): any {
		return dto;
	}

}
