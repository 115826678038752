import process from 'process';

import { IConfig } from 'unleash-proxy-client';

import { AppError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';

/**
 * Generates the Unleash proxy client configuration based on the following env vars.
 * - `NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_PROXY_URL`
 * - `NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_PROXY_CLIENT_KEY`
 * - `NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_REFRESH_INTERVAL`
 * - `NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_APP_NAME`
 *
 * @returns The Unleash proxy client configuration.
 */
export const unleashProxyClientConfig = (): IConfig => {

	const url = new Optional(process.env.NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_PROXY_URL)
		.getOrThrow(new AppError('Unleash proxy URL unavailable'));
	const clientKey = new Optional(process.env.NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_PROXY_CLIENT_KEY)
		.getOrThrow(new AppError('Unleash proxy client key unavailable'));
	const refreshInterval = new Optional(process.env.NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_REFRESH_INTERVAL)
		.getOrDefault('300');
	const appName = new Optional(process.env.NX_PUBLIC_CORE_FEATURE_FLAG_UNLEASH_APP_NAME)
		.getOrThrow(new AppError('Unleash proxy app name unavailable'));

	return {
		url,
		clientKey,
		refreshInterval: parseInt(refreshInterval),
		appName
	};

};
