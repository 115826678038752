import { Context, useContext } from 'react';

import { permissionContext, PermissionContextValue } from './permission-context';

/**
 * Custom hook to get the permissions from the context.
 *
 * @template P - The type of the permissions.
 * @returns The permissions from the context.
 */
export const usePermissions = <P extends string>(): PermissionContextValue => {
	return useContext(permissionContext as unknown as Context<PermissionContextValue<P>>);
};
