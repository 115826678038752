import { ReactNode } from 'react';

import { usePermissions } from '@chroma-x/frontend/core/react-permission-provider';

export enum PermissionGuardMode {
	ALL_OF = 'ALL_OF',
	ANY_OF = 'ANY_OF'
}

export type PermissionGuardProps<T extends string> = {
	children: ReactNode,
	permissions: Array<T>,
	mode?: PermissionGuardMode,
	interceptingComponent?: (permissions: Array<T>, mode: PermissionGuardMode) => ReactNode
};

/**
 * PermissionGuard is a component that checks if the user has the required permissions to view the children.
 *
 * @template T - The type of the permissions.
 * @param props - The properties for the PermissionGuard.
 * @returns The children if the user has the required permissions, or the intercepting component if provided, or null.
 */
export const PermissionGuard = <T extends string>(props: PermissionGuardProps<T>): ReactNode => {

	const { children, permissions, mode = PermissionGuardMode.ALL_OF, interceptingComponent } = props;

	const perms = usePermissions();

	let accessGranted = false;
	switch (mode) {
		case PermissionGuardMode.ANY_OF:
			accessGranted = perms.hasAny(permissions);
			break;
		case PermissionGuardMode.ALL_OF:
			accessGranted = perms.hasAll(permissions);
			break;
	}

	if (accessGranted) {
		return children;
	}

	if (!interceptingComponent) {
		return null;
	}

	return interceptingComponent(permissions, mode);
};
