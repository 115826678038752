import { jwtDecode, JwtPayload } from 'jwt-decode';

import { arrayIncludesAll, arrayIncludesAny } from '@chroma-x/common/core/util';
import { AccessToken } from '@chroma-x/frontend/core/auth-handler';

type JwtPermissionPayload<P extends string> = {
	permissions: Array<P>
};

/**
 * A class to handle permissions.
 *
 * @template P - The type of permissions.
 */
export class PermissionHandler<P extends string> {

	private decodedToken: JwtPayload & JwtPermissionPayload<P>;

	/**
	 * Constructs a new instance of PermissionHandler.
	 *
	 * @param accessToken - The access token.
	 */
	public constructor(accessToken: AccessToken) {
		this.decodedToken = jwtDecode<JwtPayload & JwtPermissionPayload<P>>(accessToken);
	}

	/**
	 * Gets the permissions from the decoded token.
	 *
	 * @returns An array of permissions.
	 */
	public getPermissions(): Array<P> {
		return this.decodedToken.permissions ?? [];
	}

	/**
	 * Checks if the user has a specific permission.
	 *
	 * @param permission - The permission to check.
	 * @returns True if the user has the permission, false otherwise.
	 */
	public hasPermission(permission: P): boolean {
		return this.getPermissions().includes(permission);
	}

	/**
	 * Checks if the user has any of the specified permissions.
	 *
	 * @param permissions - The permissions to check.
	 * @returns True if the user has any of the permissions, false otherwise.
	 */
	public hasAnyPermission(permissions: Array<P>): boolean {
		return arrayIncludesAny(this.getPermissions(), permissions);
	}

	/**
	 * Checks if the user has all of the specified permissions.
	 *
	 * @param permissions - The permissions to check.
	 * @returns True if the user has all of the permissions, false otherwise.
	 */
	public hasAllPermissions(permissions: Array<P>): boolean {
		return arrayIncludesAll(this.getPermissions(), permissions);
	}

}
