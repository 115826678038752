import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
	CollectionServiceFoundation,
	registerManagedService,
	defaultCollectionServiceData,
	defaultCollectionServiceQuery,
	createFetchCollectionDefaultCommand,
	createFetchNextPageDefaultCommand,
	createApplySortDefaultCommand,
	createClearSortDefaultCommand,
	createApplyFilterDefaultCommand,
	createClearFilterDefaultCommand,
	createRefetchCollectionDefaultCommand,
	defaultCollectionServiceMetaOperation
} from '@chroma-x/frontend/core/service';
import { KeyResultHistoryModel as ViewModel } from '@chroma-x/frontend/domain/okr/domain-model';

import { KeyResultHistoryServiceCommands as ServiceCommands } from './key-result-history-service-commands';
import { KeyResultHistoryUseCases } from './use-case/key-result-history-use-cases';

// Define some defaults and aliases
export const KEY_RESULT_HISTORY_SERVICE = Symbol('KEY_RESULT_HISTORY_SERVICE');

export type KeyResultHistoryService = CollectionServiceFoundation<ViewModel, ServiceCommands>;
type Service = KeyResultHistoryService;

// Create use case instance
const useCases = new KeyResultHistoryUseCases();

export const keyResultHistoryService = createStore<Service>()(
	devtools(
		immer(
			(set, get, _storeApi) => {
				return {
					data: defaultCollectionServiceData(),
					meta: defaultCollectionServiceMetaOperation(get, set),
					command: {
						fetchCollection: createFetchCollectionDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						fetchNextPage: createFetchNextPageDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								currentPage,
								sortCriteria,
								filterCriteria
							) => useCases.fetchPage(currentPage + 1, sortCriteria, filterCriteria)
						),
						applySort: createApplySortDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						clearSort: createClearSortDefaultCommand(async (forceFetch) => {
							const currentState = get();
							currentState.command.applySort(undefined, forceFetch);
						}),
						applyFilter: createApplyFilterDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						clearFilter: createClearFilterDefaultCommand(async (forceFetch) => {
							const currentState = get();
							currentState.command.applyFilter(undefined, forceFetch);
						}),
						refetchCollection: createRefetchCollectionDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						)
					},
					query: defaultCollectionServiceQuery(get)
				};
			}
		),
		{ name: 'key-result-history-service', store: 'key-result-history-service' }
	)
);

registerManagedService(keyResultHistoryService);
