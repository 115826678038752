/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { guardProbably } from '@chroma-x/common/core/util';
import { AbstractResponseModelConverter, ModelPrimaryKeyConverter, TimestampConverter } from '@chroma-x/frontend/core/api-integration';
import { ObjectiveHistoryModel, ObjectiveStatus } from '@chroma-x/frontend/domain/okr/domain-model';

import { ObjectiveHistoryDto } from '../../../../dto';

export class ObjectiveHistoryResponseModelConverter extends AbstractResponseModelConverter<ObjectiveHistoryModel, ObjectiveHistoryDto> {
	public toModel(dto: ObjectiveHistoryDto): ObjectiveHistoryModel {
		return {
			id: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.id, new ApiError("Expected property 'id' of 'ObjectiveHistoryDto' missing."))
			),
			objective: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.objective, new ApiError("Expected property 'objective' of 'ObjectiveHistoryDto' missing."))
			),
			status: guardProbably(
				dto.status,
				new ApiError("Expected property 'status' of 'ObjectiveHistoryDto' missing.")
			) as ObjectiveStatus,
			createdAt: new TimestampConverter().toModel(
				guardProbably(dto.createdAt, new ApiError("Expected property 'createdAt' of 'ObjectiveHistoryDto' missing."))
			),
			createdBy: guardProbably(
				dto.createdBy,
				new ApiError("Expected property 'createdBy' of 'ObjectiveHistoryDto' missing.")
			) satisfies string
		};
	}
}
