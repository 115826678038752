import { ObjectiveServiceApiClient } from '@chroma-x/frontend/domain/okr/api-integration';
import { ObjectiveApiFetchMockConfiguration } from '@chroma-x/frontend/domain/okr/api-integration-mock';

export class ObjectiveServiceApiClientFactory {

	public static create() {
		if (process.env.NX_PUBLIC_OKR_API_MOCKED === 'true') {
			new ObjectiveApiFetchMockConfiguration().apply();
		}
		return new ObjectiveServiceApiClient();
	}

}
