/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import {
	CollectionPaginationResponse,
	FilterCriteria,
	PaginatedCollectionResponse,
	SortCriteria,
	extractPaginationResponse
} from '@chroma-x/common/core/api-integration';
import { AppError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';
import { JsonRestRequest, UrlPathParameter, UrlQueryParameter, buildApiEndpointUrl } from '@chroma-x/frontend/core/api-integration';
import { AccessTokenLoader, AccessTokenLoaderClosure, AuthHandler } from '@chroma-x/frontend/core/auth-handler';
import { InitiativeHistoryModel } from '@chroma-x/frontend/domain/okr/domain-model';

import { InitiativeHistoryDto } from '../dto';
import { InitiativeHistoryResponseModelConverter } from '../model-converter/response';

export class InitiativeHistoryServiceApiClient {
	private readonly apiBaseUrl: string;
	private readonly accessTokenLoader: AccessTokenLoaderClosure;

	public constructor() {
		this.apiBaseUrl = new Optional(process.env.NX_PUBLIC_OKR_API_BASE_URL).getOrThrow(
			new AppError('InitiativeHistoryService API base URL unavailable')
		);
		this.accessTokenLoader = AccessTokenLoader.createLoader(AuthHandler.get());
	}

	public async headPage(page = 1, size = 50, filter?: FilterCriteria<InitiativeHistoryModel>): Promise<CollectionPaginationResponse> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/initiative-history/initiative-history-entries',
			new Map<string, UrlPathParameter>([
				['page', page],
				['size', size]
			]),
			new Map<string, UrlQueryParameter>([['filter', filter]])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).head(url);
		return extractPaginationResponse(parsedResponse.headers);
	}

	public async fetchPage(
		page = 1,
		size = 50,
		sort?: SortCriteria<InitiativeHistoryModel>,
		filter?: FilterCriteria<InitiativeHistoryModel>
	): Promise<PaginatedCollectionResponse<InitiativeHistoryModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/initiative-history/initiative-history-entries',
			new Map<string, UrlPathParameter>([
				['page', page],
				['size', size]
			]),
			new Map<string, UrlQueryParameter>([
				['sort', sort],
				['filter', filter]
			])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).get(url);
		const dto = parsedResponse.body as Array<InitiativeHistoryDto>;
		const items = new InitiativeHistoryResponseModelConverter().toArrayOfModel(dto);
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}
}
