import { RequestModelConverter } from './request-model-converter';

export abstract class AbstractRequestModelConverter<Model = unknown, Dto = unknown>
	implements RequestModelConverter<Model, Dto> {

	/**
	 * Converts a single model to a DTO.
	 *
	 * @param model - The model to convert.
	 * @returns The converted DTO.
	 */
	public abstract toDto(model: Model): Dto ;

	/**
	 * Converts an array of models to an array of DTOs.
	 *
	 * @param models - The array of models to convert.
	 * @returns The array of converted DTOs.
	 */
	public toArrayOfDto(models: Array<Model>): Array<Dto> {
		return models.map(model => this.toDto(model));
	}

}
