import { ReactNode, useState } from 'react';

import { ModelPrimaryKey, SortDirection } from '@chroma-x/common/core/api-integration';
import { ObjectiveServicePermission } from '@chroma-x/common/domain/okr/auth';
import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { usePermissions } from '@chroma-x/frontend/core/react-permission-provider';
import { withUiErrorHandler } from '@chroma-x/frontend/core/react-ui-error-handler';
import { ObjectiveServiceProvider } from '@chroma-x/frontend/domain/okr/react-service-provider';

import { ObjectiveAddFollowerForm } from '../components/objective/objective-add-follower-form';
import { ObjectiveCollection } from '../components/objective/objective-collection';
import { ObjectiveCreateForm } from '../components/objective/objective-create-form';
import { ObjectiveRemoveFollowerForm } from '../components/objective/objective-remove-follower-form';
import { ObjectiveSetFollowersForm } from '../components/objective/objective-set-followers-form';
import { ObjectiveUpdateForm } from '../components/objective/objective-update-form';

const ServiceProviderWithUiErrorHandler = withUiErrorHandler(ObjectiveServiceProvider);

export function ObjectiveCollectionView() {

	const auth = useAuth();
	const permissions = usePermissions();

	const [selectedEntry, setSelectedEntry] = useState<ModelPrimaryKey>();

	const handleLogout = () => {
		auth.unauthenticate();
	};

	const handleSelect = (entryId: ModelPrimaryKey): void => {
		setSelectedEntry(entryId);
	};

	const renderUpdateForm = (): ReactNode => {
		if (!selectedEntry) {
			return;
		}
		return (
			<>
				<ObjectiveUpdateForm id={selectedEntry} />
				<ObjectiveAddFollowerForm id={selectedEntry} />
				<ObjectiveRemoveFollowerForm id={selectedEntry} />
				<ObjectiveSetFollowersForm id={selectedEntry} />
			</>
		);
	};

	return (
		<div>
			<pre>
				Permission '{ObjectiveServicePermission.OKR_OBJECTIVE_LIST}' is {permissions.has(ObjectiveServicePermission.OKR_OBJECTIVE_LIST) ? 'GRANTED' : 'DENIED'}
			</pre>
			<hr />
			<pre>Objectives</pre>
			<hr />
			<ServiceProviderWithUiErrorHandler
				sortCriteria={[
					{ property: 'status', direction: SortDirection.ASCENDING }
				]}
			>
				<ObjectiveCollection onSelect={handleSelect} />
				<ObjectiveCreateForm />
				{renderUpdateForm()}
			</ServiceProviderWithUiErrorHandler>
			<hr />
			<button onClick={handleLogout}>Logout</button>
		</div>
	);
}
