import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { EventBroker } from '@chroma-x/frontend/core/event-broker';
import {
	CollectionServiceFoundation,
	registerManagedService,
	defaultCollectionServiceData,
	Action,
	collectionEntityActionTransactionStart,
	collectionEntityActionTransactionSuccess,
	collectionEntityActionTransactionFailed,
	defaultCollectionServiceQuery,
	createFetchCollectionDefaultCommand,
	createFetchNextPageDefaultCommand,
	createApplySortDefaultCommand,
	createClearSortDefaultCommand,
	createApplyFilterDefaultCommand,
	createClearFilterDefaultCommand,
	createRefetchCollectionDefaultCommand,
	createFetchCollectionEntityDefaultCommand,
	createRefetchCollectionEntityDefaultCommand,
	createCreateCollectionEntityDefaultCommand,
	createMutateCollectionEntityDefaultCommand,
	createDeleteCollectionEntityDefaultCommand,
	defaultCollectionServiceMetaOperation, updateCollectionEntity
} from '@chroma-x/frontend/core/service';
import {
	ObjectiveCreateModel as CreateModel,
	ObjectiveMutateModel as MutateModel,
	ObjectiveModel as ViewModel,
	IdentityKey, ObjectiveModel
} from '@chroma-x/frontend/domain/okr/domain-model';

import {
	ObjectiveEvent as Event,
	ObjectiveEventPayload as EventPayload
} from './event/objective-service-event';
import {
	ObjectiveServiceCommands,
	ObjectiveServiceCommands as ServiceCommands
} from './objective-service-commands';
import { ObjectiveUseCases } from './use-case/objective-use-cases';

// Define some defaults and aliases
export const OBJECTIVE_SERVICE = Symbol('OBJECTIVE_SERVICE');
const SERVICE = OBJECTIVE_SERVICE;

export type ObjectiveService = CollectionServiceFoundation<ViewModel, ServiceCommands>;
type Service = ObjectiveService;

// Create use case instance
const useCases = new ObjectiveUseCases();

// Aliases without generic attributes
const collectionEntityActionStart = collectionEntityActionTransactionStart<Service, ViewModel, ServiceCommands>;
const collectionEntityActionSuccess = collectionEntityActionTransactionSuccess<Service, ViewModel, ServiceCommands>;
const collectionEntityActionFailed = collectionEntityActionTransactionFailed<Service, ViewModel, ServiceCommands>;

export const objectiveService = createStore<Service>()(
	devtools(
		immer(
			(set, get, _storeApi) => {
				return {
					data: defaultCollectionServiceData(),
					meta: defaultCollectionServiceMetaOperation(get, set),
					command: {
						fetchCollection: createFetchCollectionDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						fetchNextPage: createFetchNextPageDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								currentPage,
								sortCriteria,
								filterCriteria
							) => useCases.fetchPage(currentPage + 1, sortCriteria, filterCriteria)
						),
						applySort: createApplySortDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						clearSort: createClearSortDefaultCommand(async (forceFetch) => {
							const currentState = get();
							currentState.command.applySort(undefined, forceFetch);
						}),
						applyFilter: createApplyFilterDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						clearFilter: createClearFilterDefaultCommand(async (forceFetch) => {
							const currentState = get();
							currentState.command.applyFilter(undefined, forceFetch);
						}),
						refetchCollection: createRefetchCollectionDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						fetchCollectionEntity: createFetchCollectionEntityDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (id) => useCases.fetchEntity(id)
						),
						refetchCollectionEntity: createRefetchCollectionEntityDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (id) => useCases.fetchEntity(id)
						),
						create: createCreateCollectionEntityDefaultCommand<Service, ViewModel, ServiceCommands, CreateModel>(
							set,
							async (createModel) => useCases.create(createModel),
							(model) => EventBroker.get()
								.publish<EventPayload>(SERVICE, Event.CREATED, { primaryKey: model.id })
						),
						mutate: createMutateCollectionEntityDefaultCommand<Service, ViewModel, ServiceCommands, MutateModel>(
							get,
							set,
							async (id, mutation) => useCases.mutate(id, mutation),
							(model) => EventBroker.get()
								.publish<EventPayload>(SERVICE, Event.MUTATED, { primaryKey: model.id })
						),
						delete: createDeleteCollectionEntityDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (id) => useCases.delete(id),
							(id) => EventBroker.get()
								.publish<EventPayload>(SERVICE, Event.DELETED, { primaryKey: id })
						),
						addFollower: async (id: ModelPrimaryKey, follower: IdentityKey) => {
							try {
								collectionEntityActionStart(set, id, Action.COMMAND);
								const response = await useCases.addFollower(id, follower);
								const model = response.item;
								if (model !== null) {
									updateCollectionEntity<ObjectiveService, ObjectiveModel, ObjectiveServiceCommands>(get, set, id, model);
								}
								EventBroker.get().publish<EventPayload>(
									SERVICE,
									Event.FOLLOWERS_CHANGED,
									{ primaryKey: id }
								);
								collectionEntityActionSuccess(set, id, Action.COMMAND);
							} catch (error) {
								collectionEntityActionFailed(set, id, Action.COMMAND, error as Error);
							}
						},
						removeFollower: async (id: ModelPrimaryKey, follower: IdentityKey) => {
							try {
								collectionEntityActionStart(set, id, Action.COMMAND);
								const response = await useCases.removeFollower(id, follower);
								const model = response.item;
								if (model !== null) {
									updateCollectionEntity<ObjectiveService, ObjectiveModel, ObjectiveServiceCommands>(get, set, id, model);
								}
								EventBroker.get().publish<EventPayload>(
									SERVICE,
									Event.FOLLOWERS_CHANGED,
									{ primaryKey: id }
								);
								collectionEntityActionSuccess(set, id, Action.COMMAND);
							} catch (error) {
								collectionEntityActionFailed(set, id, Action.COMMAND, error as Error);
							}
						},
						setFollowers: async (id: ModelPrimaryKey, followers: Array<IdentityKey>) => {
							try {
								collectionEntityActionStart(set, id, Action.COMMAND);
								const response = await useCases.setFollowers(id, followers);
								const model = response.item;
								if (model !== null) {
									updateCollectionEntity<ObjectiveService, ObjectiveModel, ObjectiveServiceCommands>(get, set, id, model);
								}
								EventBroker.get().publish<EventPayload>(
									SERVICE,
									Event.FOLLOWERS_CHANGED,
									{ primaryKey: id }
								);
								collectionEntityActionSuccess(set, id, Action.COMMAND);
							} catch (error) {
								collectionEntityActionFailed(set, id, Action.COMMAND, error as Error);
							}
						}
					},
					query: defaultCollectionServiceQuery(get)
				};
			}
		),
		{ name: 'objective-collection-service', store: 'objective-collection-service' }
	)
);

EventBroker.get().subscribeAll<EventPayload>(
	SERVICE,
	(payload, event) => {
		console.debug('All event handler', event, payload);
		const affectedModel = objectiveService.getState().query.queryEntity(payload.primaryKey);
		console.debug('Affected model', affectedModel);
	}
);

EventBroker.get().subscribeAny<EventPayload>(
	SERVICE,
	[Event.FOLLOWERS_CHANGED, Event.MUTATED],
	(payload, event) => {
		console.debug('Any event handler', event, payload);
		const affectedModel = objectiveService.getState().query.queryEntity(payload.primaryKey);
		console.debug('Affected model', affectedModel);
	}
);

EventBroker.get().subscribe<EventPayload>(
	SERVICE,
	Event.FOLLOWERS_CHANGED,
	(payload, event) => {
		console.debug('Event handler', event, payload);
		const affectedModel = objectiveService.getState().query.queryEntity(payload.primaryKey);
		console.debug('Affected model', affectedModel);
	}
);

registerManagedService(objectiveService);
