/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { KeyResultBasedProgressObjectiveMutateModel, ObjectiveProgressMode } from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveMutateRequestModelConverter } from './base-objective-mutate-request-model-converter';
import { KeyResultBasedProgressObjectiveMutateDto } from '../../../../../dto';

export class KeyResultBasedProgressObjectiveMutateRequestModelConverter extends BaseObjectiveMutateRequestModelConverter {
	public toDto(model: KeyResultBasedProgressObjectiveMutateModel): KeyResultBasedProgressObjectiveMutateDto {
		return {
			...super.toDto(model),
			progressMode: model.progressMode satisfies ObjectiveProgressMode.KEY_RESULTS
		};
	}
}
