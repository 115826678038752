/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */

export enum InitiativeStatus {
	NOT_STARTED = 'NOT_STARTED',
	STARTED = 'STARTED',
	CANCELLED = 'CANCELLED',
	DONE = 'DONE'
}
