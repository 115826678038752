import { useParams } from 'react-router-dom';

import { NotFoundError } from '@chroma-x/common/core/error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { withUiErrorHandler } from '@chroma-x/frontend/core/react-ui-error-handler';
import { ObjectiveServiceProvider } from '@chroma-x/frontend/domain/okr/react-service-provider';

import { Objective } from '../components/objective/objective';

const ServiceProviderWithUiErrorHandler = withUiErrorHandler(ObjectiveServiceProvider);

export function ObjectiveView() {

	const l10n = useL10n();

	const { objectiveId = null } = useParams();

	if (objectiveId === null) {
		throw new NotFoundError(l10n.translate('okr.reactUiDomain.error.objectiveNotFound'));
	}

	return (
		<div>
			<pre>Objective</pre>
			<hr />
			<ServiceProviderWithUiErrorHandler>
				<Objective id={objectiveId} />
			</ServiceProviderWithUiErrorHandler>
		</div>
	);

}
