import { InitiativeHistoryServiceApiClient } from '@chroma-x/frontend/domain/okr/api-integration';
import { InitiativeHistoryApiFetchMockConfiguration } from '@chroma-x/frontend/domain/okr/api-integration-mock';

export class InitiativeHistoryServiceApiClientFactory {

	public static create() {
		if (process.env.NX_PUBLIC_OKR_API_MOCKED === 'true') {
			new InitiativeHistoryApiFetchMockConfiguration().apply();
		}
		return new InitiativeHistoryServiceApiClient();
	}

}
