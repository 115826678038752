import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';

export enum ObjectiveEvent {
	CREATED = 'CREATED',
	MUTATED = 'MUTATED',
	DELETED = 'DELETED',
	FOLLOWERS_CHANGED = 'FOLLOWERS_CHANGED'
}

export type ObjectiveEventPayload = {
	primaryKey: ModelPrimaryKey
};
