import { KeyResultHistoryServiceApiClient } from '@chroma-x/frontend/domain/okr/api-integration';
import { KeyResultHistoryApiFetchMockConfiguration } from '@chroma-x/frontend/domain/okr/api-integration-mock';

export class KeyResultHistoryServiceApiClientFactory {

	public static create() {
		if (process.env.NX_PUBLIC_OKR_API_MOCKED === 'true') {
			new KeyResultHistoryApiFetchMockConfiguration().apply();
		}
		return new KeyResultHistoryServiceApiClient();
	}

}
