/* eslint-disable @typescript-eslint/await-thenable */
import process from 'process';

import fetchMock from 'fetch-mock';

import { AppError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';
import { buildApiEndpointUrl } from '@chroma-x/frontend/core/api-integration';
import {
	createMockBeginMatcher,
	createMockCollectionResponseData,
	createMockHeadResponse,
	createMockOptions,
	createMockResponse,
	preconfigureFetchMock
} from '@chroma-x/frontend/core/api-integration-mock';

import { objectiveHistoryDtoMockData } from '../mock-data';

export class ObjectiveHistoryApiFetchMockConfiguration {

	private static mockConfigured = false;

	private readonly apiBaseUrl: string;

	public constructor() {
		this.apiBaseUrl = new Optional(process.env.NX_PUBLIC_OKR_API_BASE_URL)
			.getOrThrow(new AppError('OKR API base URL unavailable'));
	}

	public apply() {
		if (ObjectiveHistoryApiFetchMockConfiguration.mockConfigured) {
			return;
		}

		preconfigureFetchMock();

		fetchMock
			.head(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective-history/objective-history-entries'), true),
				createMockHeadResponse(200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['28'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective-history/objective-history-entries', new Map([['page', 1]])), true),
				createMockResponse(createMockCollectionResponseData(objectiveHistoryDtoMockData, 1, 50), 200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective-history/objective-history-entries', new Map([['page', 2]])), true),
				createMockResponse(createMockCollectionResponseData(objectiveHistoryDtoMockData, 2, 50), 200, [
					{ key: 'X-Pagination-Page', values: ['2'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective-history/objective-history-entries', new Map([['page', 3]])), true),
				createMockResponse(createMockCollectionResponseData(objectiveHistoryDtoMockData, 3, 50, 28), 200, [
					{ key: 'X-Pagination-Page', values: ['3'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			);

		ObjectiveHistoryApiFetchMockConfiguration.mockConfigured = true;
	}

}
