import { useContext } from 'react';

import { envContext, EnvContextValue } from './env-context';

/**
 * Custom React hook to access the environment context.
 *
 * @returns The environment context.
 */
export const useEnv = (): EnvContextValue => {
	return useContext(envContext);
};
