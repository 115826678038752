import process from 'process';

import { useFlag } from '@unleash/proxy-client-react';

/**
 * Custom hook to check the feature flag status. It wraps around the `useFlag` hook provided by Unleash SDK to enable / disable feature
 * toggles based on the env var `NX_PUBLIC_CORE_FEATURE_FLAG_MOCKED`. If `NX_PUBLIC_CORE_FEATURE_FLAG_MOCKED` is `'true'` all feature flags
 * return `true`.
 *
 * @param featureName - The name of the feature flag.
 * @returns The status of the feature flag.
 */
export const useFeatureFlag = <T extends string = string>(featureName: T): boolean => {
	if (process.env.NX_PUBLIC_CORE_FEATURE_FLAG_MOCKED === 'true') {
		return true;
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useFlag(featureName);
};
