import { useState } from 'react';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { NotFoundError } from '@chroma-x/common/core/error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { IdentityKey } from '@chroma-x/frontend/domain/okr/domain-model';
import { useObjectiveService } from '@chroma-x/frontend/domain/okr/react-service-provider';

export type ObjectiveSetFollowersFormProps = {
	id: ModelPrimaryKey
};

export function ObjectiveSetFollowersForm(props: ObjectiveSetFollowersFormProps) {

	const { id } = props;

	const l10n = useL10n();
	const service = useObjectiveService();
	const objective = service.query.queryEntity(id)
		.getOrThrow(new NotFoundError(l10n.translate('okr.reactUiDomain.error.objectiveNotFound')));

	const [followers, setFollowers] = useState<Array<IdentityKey>>(objective.followers);

	const handleSetFollowers = () => {
		void service.command.setFollowers(id, followers);
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<fieldset>
				<legend>Set followers for objective with ID {id}</legend>
				<label>
					Followers
					<textarea name="followers" defaultValue={followers.join('\n')} onChange={(e) => {
						setFollowers(e.currentTarget.value.split('\n').map(follower => follower.trim()));
					}} />
				</label>
				<button onClick={handleSetFollowers}>
					Set followers
				</button>
			</fieldset>
		</form>
	);
}
