/* eslint-disable @typescript-eslint/await-thenable */
import process from 'process';

import fetchMock from 'fetch-mock';

import { AppError } from '@chroma-x/common/core/error';
import { JsonTransportValue } from '@chroma-x/common/core/json';
import { mutate, Optional, uniqueFilter } from '@chroma-x/common/core/util';
import { buildApiEndpointUrl } from '@chroma-x/frontend/core/api-integration';
import {
	createMockBeginMatcher,
	createMockCollectionResponseData,
	createMockEntityResponseData,
	createMockDeleteResponse,
	createMockHeadResponse,
	createMockMatcher,
	createMockOptions,
	createMockResponse,
	createMockResponseFunction,
	preconfigureFetchMock,
	ResponseHeader,
	createMockBeginAndContainsMatcher
} from '@chroma-x/frontend/core/api-integration-mock';
import { FollowerRequestDto, FollowersRequestDto } from '@chroma-x/frontend/domain/okr/api-integration';

import { objectiveDtoMockData } from '../mock-data';

export class ObjectiveApiFetchMockConfiguration {

	private static mockConfigured = false;

	private readonly apiBaseUrl: string;

	public constructor() {
		this.apiBaseUrl = new Optional(process.env.NX_PUBLIC_OKR_API_BASE_URL)
			.getOrThrow(new AppError('OKR API base URL unavailable'));
	}

	public apply() {
		if (ObjectiveApiFetchMockConfiguration.mockConfigured) {
			return;
		}

		preconfigureFetchMock();

		fetchMock
			.head(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective/objectives'), true),
				createMockHeadResponse(200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['28'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective/objectives', new Map([['page', 1]])), true),
				createMockResponse(createMockCollectionResponseData(objectiveDtoMockData, 1, 50), 200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective/objectives', new Map([['page', 2]])), true),
				createMockResponse(createMockCollectionResponseData(objectiveDtoMockData, 2, 50), 200, [
					{ key: 'X-Pagination-Page', values: ['2'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'okr/objective/objectives', new Map([['page', 3]])), true),
				createMockResponse(createMockCollectionResponseData(objectiveDtoMockData, 3, 50, 28), 200, [
					{ key: 'X-Pagination-Page', values: ['3'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.head(
				createMockBeginMatcher(`${this.apiBaseUrl}okr/objective/objective`, true),
				createMockResponseFunction(
					async (): Promise<JsonTransportValue> => undefined,
					204,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.get(
				createMockMatcher(`${this.apiBaseUrl}okr/objective/objective`, ['filter'], true),
				createMockResponse(objectiveDtoMockData, 200, this.createHypermediaLinkMocks()),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(`${this.apiBaseUrl}okr/objective/objective`, true),
				createMockResponseFunction(
					async (url): Promise<JsonTransportValue> => {
						const requestId = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0)
							.pop();
						return createMockEntityResponseData(objectiveDtoMockData, requestId);
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.post(
				createMockMatcher(`${this.apiBaseUrl}okr/objective/objective`, true),
				createMockResponseFunction(
					async (_, opts): Promise<JsonTransportValue> => {
						const requestBody = await opts.body;
						const requestData: Record<string, JsonTransportValue> = requestBody ? JSON.parse(requestBody.toString()) : {};
						return createMockEntityResponseData(mutate(objectiveDtoMockData, requestData));
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.patch(
				createMockBeginMatcher(`${this.apiBaseUrl}okr/objective/objective`, true),
				createMockResponseFunction(
					async (url, opts): Promise<JsonTransportValue> => {
						const requestId = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0)
							.pop();
						const requestBody = await opts.body;
						const requestData = requestBody ? JSON.parse(requestBody.toString()) : {};
						return createMockEntityResponseData(mutate(objectiveDtoMockData, requestData), requestId);
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.put(
				createMockBeginAndContainsMatcher(`${this.apiBaseUrl}okr/objective/objective`, 'add-follower', true),
				createMockResponseFunction(
					async (url, opts): Promise<JsonTransportValue> => {
						const pathParts = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0);
						const requestId = pathParts[pathParts.length - 2];
						const requestBody = await opts.body;
						const requestData = (requestBody ? JSON.parse(requestBody.toString()) : {}) as FollowerRequestDto;
						const resultingFollowers = objectiveDtoMockData.followers;
						resultingFollowers.push(requestData.follower);
						const mockEntityResponseData = createMockEntityResponseData(mutate(objectiveDtoMockData, {
							followers: resultingFollowers.filter(uniqueFilter)
						}), requestId);
						return mockEntityResponseData;
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.put(
				createMockBeginAndContainsMatcher(`${this.apiBaseUrl}okr/objective/objective`, 'remove-follower', true),
				createMockResponseFunction(
					async (url, opts): Promise<JsonTransportValue> => {
						const pathParts = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0);
						const requestId = pathParts[pathParts.length - 2];
						const requestBody = await opts.body;
						const requestData = (requestBody ? JSON.parse(requestBody.toString()) : {}) as FollowerRequestDto;
						const resultingFollowers = objectiveDtoMockData.followers.filter((follower) => follower !== requestData.follower);
						return createMockEntityResponseData(mutate(objectiveDtoMockData, {
							followers: resultingFollowers
						}), requestId);
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.put(
				createMockBeginAndContainsMatcher(`${this.apiBaseUrl}okr/objective/objective`, 'set-followers', true),
				createMockResponseFunction(
					async (url, opts): Promise<JsonTransportValue> => {
						const pathParts = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0);
						const requestId = pathParts[pathParts.length - 2];
						const requestBody = await opts.body;
						const requestData = (requestBody ? JSON.parse(requestBody.toString()) : {}) as FollowersRequestDto;
						return createMockEntityResponseData(mutate(objectiveDtoMockData, {
							followers: requestData.followers.filter(uniqueFilter)
						}), requestId);
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.put(
				createMockBeginMatcher(`${this.apiBaseUrl}okr/objective/objective`, true),
				createMockResponseFunction(
					async (url, opts): Promise<JsonTransportValue> => {
						const requestId = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0)
							.pop();
						const requestBody = await opts.body;
						const requestData = requestBody ? JSON.parse(requestBody.toString()) : {};
						return createMockEntityResponseData(mutate(objectiveDtoMockData, requestData), requestId);
					},
					200,
					async (): Promise<Array<ResponseHeader>> => this.createHypermediaLinkMocks()
				),
				createMockOptions()
			)
			.delete(
				createMockBeginMatcher(`${this.apiBaseUrl}okr/objective/objective`, true),
				createMockResponseFunction(
					async (url): Promise<JsonTransportValue> => {
						const requestId = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0)
							.pop();
						return createMockDeleteResponse(requestId ?? 'notFound');
					},
					200
				),
				createMockOptions()
			);

		ObjectiveApiFetchMockConfiguration.mockConfigured = true;
	}

	private createHypermediaLinkMocks(): Array<ResponseHeader> {
		return [
			{
				key: 'Link',
				values: [`<${encodeURI(`${this.apiBaseUrl}okr/objective/objective/ID/example-operation`)}>; rel="Operation name"`]
			}
		];
	}

}
