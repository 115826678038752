/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';
import { ObjectiveCreateModel, ObjectiveProgressMode } from '@chroma-x/frontend/domain/okr/domain-model';

import { KeyResultBasedProgressObjectiveCreateRequestModelConverter } from './key-result-based-progress-objective-create-request-model-converter';
import { ManualProgressObjectiveCreateRequestModelConverter } from './manual-progress-objective-create-request-model-converter';
import { ObjectiveCreateDto } from '../../../../../dto';

export class ObjectiveCreateRequestModelConverter extends AbstractRequestModelConverter<ObjectiveCreateModel, ObjectiveCreateDto> {
	public toDto(model: ObjectiveCreateModel): ObjectiveCreateDto {
		switch (model.progressMode) {
			case ObjectiveProgressMode.MANUAL:
				return new ManualProgressObjectiveCreateRequestModelConverter().toDto(model);
			case ObjectiveProgressMode.KEY_RESULTS:
				return new KeyResultBasedProgressObjectiveCreateRequestModelConverter().toDto(model);
		}
	}
}
