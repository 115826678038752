import React, { ReactElement, ReactNode } from 'react';

import { AccessToken, RefreshToken } from '@chroma-x/frontend/core/auth-handler';
import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';

export type AuthGuardInterceptingProps = {
	onAuthenticate?: (
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		idToken: AccessToken,
		idTokenValidTo: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date
	) => void
};

type AuthGuardProps = {
	children: ReactNode,
	interceptingComponent: () => ReactElement<AuthGuardInterceptingProps>
};

/**
 * AuthGuard component.
 *
 * This component is responsible for guarding parts of the view hierarchy that require authentication.
 * If the user is authenticated, the children components are rendered.
 * If the user is not authenticated, the interceptingComponent is rendered
 * and the onAuthenticate function is passed to it.
 *
 * @param props - The props for the AuthGuard component.
 * @returns The rendered component.
 */
export const AuthGuard = (props: AuthGuardProps) => {
	const {
		children,
		interceptingComponent
	} = props;

	const auth = useAuth();

	if (auth.isAuthenticated()) {
		return children;
	}

	return React.cloneElement(interceptingComponent(), {
		onAuthenticate: (
			accessToken: AccessToken,
			accessTokenValidTo: Date,
			idToken: AccessToken,
			idTokenValidTo: Date,
			refreshToken?: RefreshToken,
			refreshTokenValidTo?: Date
		): void => {
			auth.authenticate(
				accessToken,
				accessTokenValidTo,
				idToken,
				idTokenValidTo,
				refreshToken,
				refreshTokenValidTo
			);
		}
	});
};
