import process from 'process';

import { FlagProvider, IFlagProvider } from '@unleash/proxy-client-react';
import React, { ReactNode } from 'react';

export type FeatureFlagProviderProps = IFlagProvider & {
	children: ReactNode
};

/**
 * A component that provides feature flags to its children. It wraps around `FlagProvider` of Unleash to enable / disable feature toggles
 * based on the env var `NX_PUBLIC_CORE_FEATURE_FLAG_MOCKED`.
 *
 * @param props - The component props.
 * @param props.children - The children to render.
 * @param props.restProps - The remaining props to pass to the FlagProvider.
 * @returns The rendered component.
 */
export const FeatureFlagProvider = (props: FeatureFlagProviderProps) => {

	const { children, ...restProps } = props;

	if (process.env.NX_PUBLIC_CORE_FEATURE_FLAG_MOCKED === 'true') {
		return children;
	}

	return (
		<FlagProvider {...restProps}>
			{children}
		</FlagProvider>
	);

};
