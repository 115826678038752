/* eslint-disable @typescript-eslint/naming-convention */
import { AuthenticationFailedError, TimeoutError } from '@chroma-x/common/core/error';
import { Timeout } from '@chroma-x/common/core/util';

type TokenRequestResponseModel = {
	id_token: string,
	access_token: string,
	expires_in: number,
	exp: number
};

/**
 * Builds the URL for the Keycloak realm.
 *
 * @param baseUrl - The base URL of the Keycloak instance.
 * @param realm - The name of the Keycloak realm.
 * @returns The URL for the Keycloak realm.
 */
const buildRealmUrl = (baseUrl: string, realm: string): string => {
	return baseUrl + 'auth/realms/' + realm;
};

/**
 * Requests a token from Keycloak using the client credentials grant.
 *
 * @param baseUrl - The base URL of the Keycloak instance.
 * @param realm - The name of the Keycloak realm.
 * @param clientId - The client ID.
 * @param clientSecret - The client secret.
 * @param additionalHeaders - Additional headers to include in the request.
 * @returns A Promise that resolves to a TokenRequestResponseModel.
 * @throws AuthenticationFailedError if the token request fails.
 */
export const keycloakRequestTokenBySecret = async (
	baseUrl: string,
	realm: string,
	clientId: string,
	clientSecret: string,
	additionalHeaders?: Record<string, string>
): Promise<TokenRequestResponseModel> => {
	const abortController = new AbortController();

	const headers = {
		...additionalHeaders ?? {},
		'Content-Type': 'application/x-www-form-urlencoded'
	};

	const request = new Request(
		buildRealmUrl(baseUrl, realm) + '/protocol/openid-connect/token',
		{
			signal: abortController.signal,
			method: 'POST',
			cache: 'no-cache',
			headers: headers,
			body: new URLSearchParams({
				grant_type: 'client_credentials',
				client_id: clientId ?? '',
				client_secret: clientSecret ?? '',
				scope: 'openid'
			}).toString()
		}
	);

	const response = await Timeout.wrap<Response>(fetch(request), 5000, new TimeoutError('Request timeout'), (): void => {
		abortController.abort();
	});
	if (!response.ok) {
		throw new AuthenticationFailedError('Token request failed');
	}

	let responseBody: TokenRequestResponseModel;
	try {
		responseBody = await response.json();
	} catch (e) {
		throw new AuthenticationFailedError('Token response invalid');
	}

	return responseBody;
};
