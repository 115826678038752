/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
export enum InitiativeServicePermission {
	OKR_INITIATIVE_LIST = 'okr:initiative:list',
	OKR_INITIATIVE_READ = 'okr:initiative:read',
	OKR_INITIATIVE_CREATE = 'okr:initiative:create',
	OKR_INITIATIVE_MUTATE = 'okr:initiative:mutate',
	OKR_INITIATIVE_DELETE = 'okr:initiative:delete',
	OKR_INITIATIVE_UPDATE = 'okr:initiative:update'
}

export const initiativeServicePermissions = [
	InitiativeServicePermission.OKR_INITIATIVE_LIST,
	InitiativeServicePermission.OKR_INITIATIVE_READ,
	InitiativeServicePermission.OKR_INITIATIVE_CREATE,
	InitiativeServicePermission.OKR_INITIATIVE_MUTATE,
	InitiativeServicePermission.OKR_INITIATIVE_DELETE,
	InitiativeServicePermission.OKR_INITIATIVE_UPDATE
];
