import { createContext } from 'react';

import { AppError } from '@chroma-x/common/core/error';

/**
 * Represents the value of the PermissionContext.
 * @template P - The type of the permissions.
 */
export type PermissionContextValue<P extends string = string> = {
	/**
	 * Checks if the given permission is present.
	 * @param permission - The permission to check.
	 * @returns True if the permission is present, false otherwise.
	 */
	has(permission: P): boolean,

	/**
	 * Checks if any of the given permissions are present.
	 * @param _permissions - The permissions to check.
	 * @returns True if any of the permissions are present, false otherwise.
	 */
	hasAny(_permissions: Array<string>): boolean,

	/**
	 * Checks if all of the given permissions are present.
	 * @param _permissions - The permissions to check.
	 * @returns True if all of the permissions are present, false otherwise.
	 */
	hasAll(_permissions: Array<string>): boolean
};

export const permissionContext = createContext<PermissionContextValue>({
	has: (_permission: string): boolean => {
		throw new AppError('No permission context provided');
	},
	hasAny: (_permissions: Array<string>): boolean => {
		throw new AppError('No permission context provided');
	},
	hasAll: (_permissions: Array<string>): boolean => {
		throw new AppError('No permission context provided');
	}
});
