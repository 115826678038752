import {
	CollectionPaginationResponse,
	FilterCriteria,
	PaginatedCollectionResponse,
	SortCriteria
} from '@chroma-x/common/core/api-integration';
import { ObjectiveHistoryServiceApiClientFactory } from '@chroma-x/frontend/domain/okr/api-integration-factory';
import { ObjectiveHistoryModel } from '@chroma-x/frontend/domain/okr/domain-model';

export abstract class ObjectiveHistoryUseCasesBase {

	public async headCollection(filter?: FilterCriteria<ObjectiveHistoryModel>): Promise<CollectionPaginationResponse> {
		return ObjectiveHistoryServiceApiClientFactory.create().headPage(1, undefined, filter);
	}

	public async fetchCollection(
		sort?: SortCriteria<ObjectiveHistoryModel>,
		filter?: FilterCriteria<ObjectiveHistoryModel>
	): Promise<PaginatedCollectionResponse<ObjectiveHistoryModel>> {
		return ObjectiveHistoryServiceApiClientFactory.create().fetchPage(1, undefined, sort, filter);
	}

	public async fetchPage(
		page = 1,
		sort?: SortCriteria<ObjectiveHistoryModel>,
		filter?: FilterCriteria<ObjectiveHistoryModel>
	): Promise<PaginatedCollectionResponse<ObjectiveHistoryModel>> {
		return ObjectiveHistoryServiceApiClientFactory.create().fetchPage(page, undefined, sort, filter);
	}

}
