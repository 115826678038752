import { Route } from 'react-router-dom';

import { useSentryRoutes } from '@chroma-x/frontend/core/react-sentry-integration';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';
import { withSentryProfiler } from '@chroma-x/frontend/core/sentry-integration';

import { RoutePath } from './routes';
import { App } from '../app';
import { GoodbyeView } from '../views/goodbye-view';

export function AuthRouter() {

	const Routes = useSentryRoutes();
	const SentryProfiledApp = withSentryProfiler(App);

	return (
		<Routes>
			<Route path={buildRouteUrl(RoutePath.GOODBYE)} element={<GoodbyeView />} />
			<Route path="*" element={<SentryProfiledApp />} />
		</Routes>
	);

}
