/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractResponseModelConverter } from '@chroma-x/frontend/core/api-integration';
import { ObjectiveModel } from '@chroma-x/frontend/domain/okr/domain-model';

import { KeyResultBasedProgressObjectiveResponseModelConverter } from './key-result-based-progress-objective-response-model-converter';
import { ManualProgressObjectiveResponseModelConverter } from './manual-progress-objective-response-model-converter';
import { ObjectiveDto } from '../../../../dto';

export class ObjectiveResponseModelConverter extends AbstractResponseModelConverter<ObjectiveModel, ObjectiveDto> {
	public toModel(dto: ObjectiveDto): ObjectiveModel {
		switch (dto.progressMode) {
			case 'MANUAL':
				return new ManualProgressObjectiveResponseModelConverter().toModel(dto);
			case 'KEY_RESULTS':
				return new KeyResultBasedProgressObjectiveResponseModelConverter().toModel(dto);
		}
	}
}
