import process from 'process';

import { withProfiler } from '@sentry/react';
import { ComponentType, FC } from 'react';

/**
 * Higher-order component that wraps a component with the Sentry Profiler
 * if Sentry is enabled and profiling is enabled by setting the `NX_PUBLIC_SENTRY_ENABLED`
 * and `NX_PUBLIC_SENTRY_PROFILING_ENABLED` env vars.
 *
 * @template P - The type of the component's props.
 * @param Component - The component to be wrapped.
 * @returns The wrapped component.
 */
export const withSentryProfiler = <P extends Record<string, unknown>>(Component: ComponentType<P>): FC<P> => {
	if (process.env.NX_PUBLIC_SENTRY_ENABLED === 'true' && process.env.NX_PUBLIC_SENTRY_PROFILING_ENABLED === 'true') {
		return withProfiler(Component);
	}
	return Component as FC<P>;
};
