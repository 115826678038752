/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ObjectiveHistoryDto } from '@chroma-x/frontend/domain/okr/api-integration';

export const objectiveHistoryDtoMockData: ObjectiveHistoryDto = {
	id: '0c0a0470-11a4-4805-a070-13162f2d9023',
	objective: '0c0a0470-11a4-4805-a070-13162f2d9023',
	status: 'STARTED',
	createdAt: '2024-05-28T16:02:57.919Z',
	createdBy: '0c0a0470-11a4-4805-a070-13162f2d9023'
};
