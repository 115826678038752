import { buildRouteUrl } from '@chroma-x/frontend/core/routing';

import { RoutePath } from '../router/routes';

export function GoodbyeView() {
	return (
		<div>
			<h1>Goodbye</h1>
			<a href={buildRouteUrl(RoutePath.ROOT)}>Start session</a>
		</div>
	);
}
