/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { guardProbably } from '@chroma-x/common/core/util';
import { AbstractResponseModelConverter, ModelPrimaryKeyConverter, TimestampConverter } from '@chroma-x/frontend/core/api-integration';
import { KeyResultHistoryModel, KeyResultStatus } from '@chroma-x/frontend/domain/okr/domain-model';

import { KeyResultHistoryDto } from '../../../../dto';

export class KeyResultHistoryResponseModelConverter extends AbstractResponseModelConverter<KeyResultHistoryModel, KeyResultHistoryDto> {
	public toModel(dto: KeyResultHistoryDto): KeyResultHistoryModel {
		return {
			id: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.id, new ApiError("Expected property 'id' of 'KeyResultHistoryDto' missing."))
			),
			keyResult: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.keyResult, new ApiError("Expected property 'keyResult' of 'KeyResultHistoryDto' missing."))
			),
			status: guardProbably(
				dto.status,
				new ApiError("Expected property 'status' of 'KeyResultHistoryDto' missing.")
			) as KeyResultStatus,
			createdAt: new TimestampConverter().toModel(
				guardProbably(dto.createdAt, new ApiError("Expected property 'createdAt' of 'KeyResultHistoryDto' missing."))
			),
			createdBy: guardProbably(
				dto.createdBy,
				new ApiError("Expected property 'createdBy' of 'KeyResultHistoryDto' missing.")
			) satisfies string
		};
	}
}
