import { useParams } from 'react-router-dom';

import { NotFoundError } from '@chroma-x/common/core/error';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { withUiErrorHandler } from '@chroma-x/frontend/core/react-ui-error-handler';
import { InitiativeHistoryServiceProvider } from '@chroma-x/frontend/domain/okr/react-service-provider';

import { InitiativeHistoryEntry } from '../components/initiative-history/initiative-history-entry';

const ServiceProviderWithUiErrorHandler = withUiErrorHandler(InitiativeHistoryServiceProvider);

export function InitiativeHistoryEntryView() {

	const l10n = useL10n();

	const { entryId = null } = useParams();

	if (entryId === null) {
		throw new NotFoundError(l10n.translate('okr.reactUiDomain.error.initiativeEntryNotFound'));
	}

	return (
		<div>
			<pre>Initiative history entry</pre>
			<hr />
			<ServiceProviderWithUiErrorHandler>
				<InitiativeHistoryEntry entryId={entryId} />
			</ServiceProviderWithUiErrorHandler>
		</div>
	);

}
