import {
	CollectionPaginationResponse,
	FilterCriteria,
	PaginatedCollectionResponse,
	SortCriteria
} from '@chroma-x/common/core/api-integration';
import { InitiativeHistoryServiceApiClientFactory } from '@chroma-x/frontend/domain/okr/api-integration-factory';
import { InitiativeHistoryModel } from '@chroma-x/frontend/domain/okr/domain-model';

export abstract class InitiativeHistoryUseCasesBase {

	public async headCollection(filter?: FilterCriteria<InitiativeHistoryModel>): Promise<CollectionPaginationResponse> {
		return InitiativeHistoryServiceApiClientFactory.create().headPage(1, undefined, filter);
	}

	public async fetchCollection(
		sort?: SortCriteria<InitiativeHistoryModel>,
		filter?: FilterCriteria<InitiativeHistoryModel>
	): Promise<PaginatedCollectionResponse<InitiativeHistoryModel>> {
		return InitiativeHistoryServiceApiClientFactory.create().fetchPage(1, undefined, sort, filter);
	}

	public async fetchPage(
		page = 1,
		sort?: SortCriteria<InitiativeHistoryModel>,
		filter?: FilterCriteria<InitiativeHistoryModel>
	): Promise<PaginatedCollectionResponse<InitiativeHistoryModel>> {
		return InitiativeHistoryServiceApiClientFactory.create().fetchPage(page, undefined, sort, filter);
	}

}
